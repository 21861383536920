<template>
  <div class="d-flex flex-column overflow-auto vh-100">
    <edit-property @hidden="selectedProperty = undefined" id="edit-property" :property="selectedProperty" />
    <sticky-header :title="$route.meta.title"></sticky-header>
    <div class="flex-fill p-3">
      <b-row class="mb-4">
        <b-col cols="12" md="9">
          <b-form-input debounce="500" @update="load" placeholder="Search for property..." type="text" v-model="query" />
        </b-col>
        <b-col cols="12" md="3">
          <b-button block @click="filtersShown = !filtersShown" variant="dark">Filters <fa-icon class="ml-2" icon="chevron-down" v-if="!filtersShown" /><fa-icon class="ml-2" icon="chevron-up" v-else /></b-button>
        </b-col>
      </b-row>
      <b-card class="mb-4" v-if="filtersShown">
        <b-row>
          <b-col cols="12" md="6">
            <b-form-group>
              <label class="mb-0" for="startPeriod">Start of Period</label>
              <b-form-datepicker id="startPeriod" v-model="selectedStartPeriod" :options="filterPeriods" />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6">
            <b-form-group>
              <label class="mb-0" for="endPeriod">End of Period</label>
              <b-form-datepicker  id="endPeriod" v-model="selectedEndPeriod" :options="filterPeriods" />
            </b-form-group>
          </b-col>
        </b-row>
      </b-card>
      <div class="d-flex flex-column mt-5 mb-4 py-3 justify-content-center" v-if="loading">
        <b-spinner class="align-self-center" variant="primary" />
        <p class="align-self-center mt-1 mb-0">Loading...</p>
      </div>
      <b-card class="text-center mt-2 py-5 mb-4" v-if="!loading && data.length === 0">
        <p class="h3 font-weight-bold">No properties with non-entries found.</p>
      </b-card>
      <template v-for="property in data">
        <b-card :key="property.id" class="mb-4">
          <div class="d-flex flex-row justify-content-between">
            <h4>{{ property.name }}</h4>
            <router-link class="mb-1 mb-md-0" :to="'/properties/update/' + property.id" variant="primary" v-b-popover.top.hover="`View Property`"><fa-icon icon="hotel" /></router-link>
          </div>
          <table class="table table-fixed" v-if="!loading">
            <thead>
            <tr>
              <th scope="col">Reason</th>
              <th scope="col">Team</th>
              <th scope="col">Job Date</th>
              <th scope="col"></th>
            </tr>
            </thead>
            <tbody class="mt-2 mb-4">
            <tr :key="noEntry.id" v-for="noEntry in property.no_entries">
              <td>{{ noEntry?.no_entry_reason }}</td>
              <td>{{ noEntry?.rota?.team?.name ?? 'Unknown' }}</td>
              <td>{{ moment(noEntry.started_at).format('DD/MM/YYYY') }}</td>
              <td>
                <router-link class="mb-1 mb-md-0" :to="'/jobs/details/' + noEntry.id" variant="primary" v-b-popover.top.hover="`View Job`"><fa-icon icon="hand-sparkles" /></router-link>
              </td>
            </tr>
            </tbody>
          </table>
          <h5>{{ property.no_entries.length }} No Entries</h5>
        </b-card>
      </template>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import pickBy from 'lodash/pickBy'
import identity from 'lodash/identity'
import StickyHeader from '../components/StickyHeader.vue'
import { mapActions, mapState } from 'vuex'
import EditProperty from "@/views/Modals/EditProperty.vue";

export default {
  components: {EditProperty, StickyHeader },
  data() {
    const periods = Array.from(Array(72).keys()).map(m => moment().subtract(m, 'months'))

    return {
      query: '',
      selectedProperty: {},
      selectedStartPeriod: moment().startOf('month').format('YYYY-MM-DD'),
      selectedEndPeriod: moment().endOf('month').format('YYYY-MM-DD'),
      filtersShown: true,
      filterPeriods: periods.map(p => ({ text: p.format('MMM YYYY'), value: p.format('YYYY-MM-[01]') }))
    };
  },
  async mounted() {
    await this.load();
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('noEntries', ['data', 'loading', 'lastPage'])
  },
  methods: {
    ...mapActions('noEntries', ['fetch']),
    moment,
    paginationLinkGen(pageNo) {
      return pageNo === 1
        ? {name: 'no-entries', query: pickBy({...this.$route.params, page: null}, identity)}
        : {name: 'no-entries', query: {...this.$route.params, page: pageNo}};
    },
    pluralise(count, term) {
      if (count === 1) return term;
      return `${term}s`;
    },
    view(id) {
      this.selectedProperty = this.data.find(c => c.id === id);

      if (!(this.$route.name === 'properties.update' && this.$route.params.propertyId === id)) {
        this.$router.push({ name: 'properties.update', params: { propertyId: id } });
      }
    },
    async load() {
      await this.fetch(Object.fromEntries(Object.entries({
        query: this.query,
        page: this.$route.query.page,
        start_month: this.selectedStartPeriod ? this.selectedStartPeriod: undefined,
        end_month: this.selectedEndPeriod ? this.selectedEndPeriod : undefined
      }).filter(([,v]) => v !== undefined)));
    }
  },
  watch: {
    async '$route'(newValue, value) {
      if (!value.meta.modalId && newValue.meta.modalId) {
        return this.$bvModal.show(newValue.meta.modalId);
      }

      if (newValue.name === value.name && newValue.query.page !== value.query.page) {
        await this.load();
      }

      return this.$bvModal.hide(value.meta.modalId);
    },
    async selectedStartPeriod() { await this.load() },
    async selectedEndPeriod() { await this.load() },
  }
}
</script>

<style>
.table-fixed {
  table-layout: fixed;
  width: 100%;
}
</style>
